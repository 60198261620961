import styled from "styled-components"

import { Select, Switch } from "@material-ui/core"
import { useFormik } from "formik"

import { StyledDivider } from "src/components/AppLayout/SideNav/sharedStyles"
import { MultiPillSelect } from "src/components/Paradise/MultiPillSelect"
import { usePatchApiClient } from "src/data/apiClients/apiClientQueries"
import { IApiClient } from "src/data/apiClients/apiClientTypes"
import { MButton } from "src/ui/Button/MButton"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { MTextField } from "src/ui/MTextField/MTextField"
import { MultipleValuesField } from "src/ui/MultipleValuesField/MultipleValuesField"
import { spacing } from "src/ui/spacing"

export function ParadiseClientSettings({ client }: { client: IApiClient }) {
  const patchApiClient = usePatchApiClient()

  const form = useFormik({
    initialValues: {
      enabled: client.enabled,
      restricted_to_owner: client.restricted_to_owner,
      first_party: client.first_party,
      can_access_third_party_data: client.can_access_third_party_data,
      minimum_api_version: client.minimum_api_version,
      roles: client.roles ?? [],
      user_required_role: client.user_required_role,
      authorization_code_grant: {
        enabled: client.authorization_code_grant.enabled,
        redirect_uris: client.authorization_code_grant.redirect_uris ?? [],
        allowed_origins: client.authorization_code_grant.allowed_origins ?? [],
        require_pkce: false,
      },
      client_credentials_grant: {
        enabled: client.client_credentials_grant.enabled,
      },
      owner_password_grant: {
        enabled: client.owner_password_grant.enabled,
      },
    },
    onSubmit: (values) => {
      patchApiClient.mutate({
        clientId: client.client_id,
        body: values,
      })
    },
  })

  return (
    <FormWrapper
      onSubmit={(e) => {
        e.preventDefault()
        form.handleSubmit()
      }}
    >
      <FormItem
        title="Enabled"
        control={
          <Switch
            checked={form.values.enabled}
            name="enabled"
            onChange={form.handleChange}
          />
        }
        description="Toggles the client. If unchecked no tokens issued to the client will work anymore."
      />
      <FormItem
        title="Restricted to owner"
        control={
          <Switch
            checked={form.values.restricted_to_owner}
            name="restricted_to_owner"
            onChange={(e, checked) => {
              form.setValues((prev) => ({
                ...prev,
                restricted_to_owner: checked,
              }))

              if (!form.values.first_party && !checked) {
                form.setValues((prev) => ({
                  ...prev,
                  owner_password_grant: {
                    ...prev.owner_password_grant,
                    enabled: false,
                  },
                }))
              }
            }}
          />
        }
        description="If this is checked then only the user owning the client, or any member of the organization owning the client can issue tokens using this client."
      />
      <FormItem
        title="First party client"
        control={
          <Switch
            checked={form.values.first_party}
            name="first_party"
            onChange={(e, checked) => {
              form.setValues((prev) => ({ ...prev, first_party: checked }))
              form.setValues((prev) => ({
                ...prev,
                can_access_third_party_data: true,
              }))

              if (!form.values.restricted_to_owner && !checked) {
                form.setValues((prev) => ({
                  ...prev,
                  owner_password_grant: {
                    ...prev.owner_password_grant,
                    enabled: false,
                  },
                }))
              }
            }}
          />
        }
        description={
          <>
            <MText variant="body">
              This should only be checked for clients owned by Minut. Checking
              this box will:
            </MText>
            <ul>
              <li>Disable requirement for a subscription</li>
              <li>
                Disable showing the consent view when authorizing the client
              </li>
            </ul>
          </>
        }
      />
      <FormItem
        title="Can Access 3:rd Party Data"
        control={
          <Switch
            checked={form.values.can_access_third_party_data}
            name="can_access_third_party_data"
            onChange={form.handleChange}
            disabled={form.values.first_party}
          />
        }
        description={
          <>
            {form.values.first_party && (
              <MBanner fullWidth type="warning">
                Note: This will always be enabled for 1:st party clients
              </MBanner>
            )}
            <MText variant="body">
              If this is checked then the client will be able to fetch guests
              originating from integrations. Otherwise they will only see guests
              originating from the Minut app.
            </MText>
          </>
        }
      />
      <FormItem
        title="Minimum API version"
        control={
          <Select
            native
            value={form.values.minimum_api_version}
            name="minimum_api_version"
            onChange={(e) => {
              form.setValues((prev) => ({
                ...prev,
                minimum_api_version: parseInt(e.target.value as string),
              }))
            }}
            fullWidth
          >
            {[...new Array(8).keys()].reverse().map((k) => (
              <option key={k} value={k++}>
                Version {k++}
              </option>
            ))}
            <option value={-1}>None (All versions allowed)</option>
          </Select>
        }
        description="This should generally be the latest (highest) version for external clients. For internal clients this should not be set, as those needs to be able to use all versions."
      />
      <FormItem
        title="Client roles"
        control={
          <MultiPillSelect
            value={
              form.values.roles?.map((role) => ({
                id: role,
                name: role,
              })) ?? []
            }
            options={[]}
            onSearch={() => {}}
            onSelected={(selected) => {
              form.setValues((prev) => ({
                ...prev,
                roles: selected.map((s) => s.id),
              }))
            }}
            onCreate={(input) => {
              form.setValues((prev) => ({
                ...prev,
                roles: [...(prev.roles ?? []), input],
              }))
            }}
            loading={false}
          />
        }
        description="The roles for the client, not to be confused with roles on the user."
      />

      <FormItem
        title="Required user role"
        control={
          <MTextField
            value={form.values.user_required_role || ""}
            name="user_required_role"
            onChange={form.handleChange}
          />
        }
        description="If this is set any user authenticating with this client must have this role set, otherwise they'll get an error when trying to issue a token."
      />
      <div>
        <MText variant="heading3">Authorization Code Grant</MText>
        <StyledDivider />
        <MText variant="body">
          The Authorization Code grant type is used by confidential and public
          clients to exchange an authorization code for an access token.
        </MText>
        <ExternalLink href="https://oauth.net/2/grant-types/authorization-code/">
          More info
        </ExternalLink>
      </div>
      <FormItem
        title="Enabled"
        control={
          <Switch
            checked={form.values.authorization_code_grant?.enabled}
            name="authorization_code_grant.enabled"
            onChange={form.handleChange}
          />
        }
      />
      <FormItem
        title="Redirect URIs"
        control={
          <MultipleValuesField
            textFields={form.values.authorization_code_grant.redirect_uris}
            onChange={(uris) => {
              form.setValues((prev) => ({
                ...prev,
                authorization_code_grant: {
                  ...prev.authorization_code_grant,
                  redirect_uris: uris,
                },
              }))
            }}
            inputType="url"
            buttonLabel="Add another redirect URI"
          />
        }
      />

      <FormItem
        title="Allowed origins"
        control={
          <MultipleValuesField
            textFields={form.values.authorization_code_grant.allowed_origins}
            onChange={(origins) => {
              form.setValues((prev) => ({
                ...prev,
                authorization_code_grant: {
                  ...prev.authorization_code_grant,
                  allowed_origins: origins,
                },
              }))
            }}
            inputType="url"
            buttonLabel="Add another allowed origin"
          />
        }
      />
      <div>
        <MText variant="heading3">Client Credentials Grant</MText>
        <StyledDivider />
        <MText variant="body">
          The Client Credentials grant type is used by clients to obtain an
          access token outside of the context of a user.
        </MText>
        <ExternalLink href="https://oauth.net/2/grant-types/client-credentials/">
          More info
        </ExternalLink>
      </div>
      <FormItem
        title="Enabled"
        control={
          <Switch
            checked={form.values.client_credentials_grant?.enabled}
            name="client_credentials_grant.enabled"
            onChange={form.handleChange}
          />
        }
      />
      <div>
        <MText variant="heading3">Resource Owner Password Grant</MText>
        <StyledDivider />
        <MText variant="body">
          The Password grant type is a legacy way to exchange a user's
          credentials for an access token. Because the client application has to
          collect the user's password and send it to the authorization server,
          it is not recommended that this grant be used at all anymore.
        </MText>
        <ExternalLink href="https://oauth.net/2/grant-types/password/">
          More info
        </ExternalLink>
      </div>
      <FormItem
        title="Enabled"
        control={
          <Switch
            checked={form.values.owner_password_grant?.enabled}
            name="owner_password_grant.enabled"
            onChange={form.handleChange}
            disabled={
              !form.values.restricted_to_owner && !form.values.first_party
            }
          />
        }
      />
      <ButtonWrapper>
        <MButton type="submit" loading={patchApiClient.isLoading}>
          Save
        </MButton>
      </ButtonWrapper>
    </FormWrapper>
  )
}

const FormWrapper = styled.form`
  display: grid;
  gap: ${spacing.XL};
  margin-bottom: ${spacing.L};
`

function FormItem({
  title,
  control,
  description,
}: {
  title: React.ReactNode
  control: React.ReactNode
  description?: React.ReactNode
}) {
  return (
    <div>
      <MText variant="subtitle" marginBottom={spacing.M}>
        {title}
      </MText>
      {control}
      <MText variant="body">{description}</MText>
    </div>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
